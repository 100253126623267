import { CheckIcon } from '@chakra-ui/icons'
import { Menu, MenuButton, MenuList, MenuItem, MenuDivider, Button, Text } from '@chakra-ui/react'
import { ECalendarViewType } from '../../../../../components/Calendar/constants'
import { backgroundGrey100, backgroundGrey200, textGrey500 } from '../../../../../theme/globalStyles'
import { ICustomizeDropdownProps } from '../../../constants'

const CustomizeDropdown = (props: ICustomizeDropdownProps) => {
  const { viewMode, onViewModeChange, showProjectList, showSalary, onToggleProjectList, onToggleSalary } = props
  return (
    <Menu closeOnSelect={false}>
      <MenuButton as={Button} backgroundColor={backgroundGrey200} variant="outline">
        Customize
      </MenuButton>
      <MenuList minW="200px" zIndex={10}>
        <Text px={3} fontSize="sm" color={textGrey500} margin={2}>
          VIEW MODE
        </Text>
        <MenuItem
          onClick={() => onViewModeChange(ECalendarViewType.TIME_GRID_DAY)}
          icon={viewMode === ECalendarViewType.TIME_GRID_DAY ? <CheckIcon /> : undefined}
          backgroundColor={viewMode === ECalendarViewType.TIME_GRID_DAY ? backgroundGrey100 : 'white'}>
          Day
        </MenuItem>
        <MenuItem
          onClick={() => onViewModeChange(ECalendarViewType.TIME_GRID_WEEK)}
          icon={viewMode === ECalendarViewType.TIME_GRID_WEEK ? <CheckIcon /> : undefined}
          backgroundColor={viewMode === ECalendarViewType.TIME_GRID_WEEK ? backgroundGrey100 : 'white'}>
          Week
        </MenuItem>
        <MenuItem
          onClick={() => onViewModeChange(ECalendarViewType.DAY_GRID_MONTH)}
          icon={viewMode === ECalendarViewType.DAY_GRID_MONTH ? <CheckIcon /> : undefined}
          backgroundColor={viewMode === ECalendarViewType.DAY_GRID_MONTH ? backgroundGrey100 : 'white'}>
          Month
        </MenuItem>

        <MenuDivider />

        <Text px={3} fontSize="sm" color={textGrey500} margin={2}>
          ADVANCED SETTING
        </Text>
        <MenuItem
          onClick={() => onToggleProjectList()}
          icon={showProjectList ? <CheckIcon /> : undefined}
          backgroundColor={showProjectList ? backgroundGrey100 : 'white'}>
          Show project list
        </MenuItem>
        <MenuItem
          onClick={() => onToggleSalary()}
          icon={showSalary ? <CheckIcon /> : undefined}
          backgroundColor={showSalary ? backgroundGrey100 : 'white'}>
          Show salary
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

export default CustomizeDropdown
