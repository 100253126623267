import { MutableRefObject, useEffect, useRef, useState } from 'react'
import { VStack, HStack, Progress, Box } from '@chakra-ui/react'
import { Text } from '@chakra-ui/react'
import FullCalendar from '@fullcalendar/react'
import { ECalendarViewType } from 'components/Calendar/constants'
import GroupChips from 'components/GroupChips'
import dayjs from 'dayjs'
import { capitalize } from 'lodash'
import { observer } from 'mobx-react'
import { FormProvider, useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { backgroundGrey50, borderGray200, textGreen500, textGrey500, textGrey700 } from 'theme/globalStyles'
import { IOption } from 'constants/common'
import { EConditionGetList, EPartnerCategory, ETimesheetSubTitle, EWorkingHourPeriods } from 'constants/enum'
import { Messages } from 'constants/index'
import { getValidArray, isValidArray } from 'utils/commonUtils'
import { generateProjectOptionsWithStatus, getActiveProjectOptions } from 'utils/dropdownOptionUtils'
import { useStores } from 'utils/hooks/useStores'
import { DEFAULT_TIMESHEET_SETTINGS, TimesheetSettings, TIMESHEET_SETTINGS_STORAGE_KEY } from '../constants'
import CalendarSection from './components/CalendarSection'
import HeaderSection from './components/HeaderSection'
import SmartTimesheetModal from './components/SmartTimesheetModal'
import TimeSheetModal from './components/TimeSheetModal'
import { getTimeLogProgressColor, getTimeLogProgressPercentage } from './utils'

const TimeSheetUser = () => {
  window.sessionStorage.setItem('subPageTitle', ETimesheetSubTitle.YOUR_TIMESHEET)
  window.dispatchEvent(new Event('storage'))

  const methods = useForm()
  const { watch, setValue } = methods
  const location = useLocation()

  const { userTimesheetStore, adminTaskTypeStore, userProjectStore, userProfileStore } = useStores()
  const { profileDetail } = userProfileStore
  const partnerCategory = profileDetail?.partnerCategory
  const isEmployee = partnerCategory === capitalize(EPartnerCategory.EMPLOYEE)
  const { totalSalary, totalExtraProjectSalary, totalWorkingTime } = userProfileStore.dashboardData
  const { projectNameList } = userProjectStore
  const { isLoadNewTimesheetList, selectedDateFromCalendar } = userTimesheetStore

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [isOpenSmartTimesheetModal, setIsOpenSmartTimesheetModal] = useState<boolean>(false)
  const [viewMode, setViewMode] = useState<ECalendarViewType>(DEFAULT_TIMESHEET_SETTINGS.viewMode)
  const [showProjectList, setShowProjectList] = useState<boolean>(DEFAULT_TIMESHEET_SETTINGS.showProjectList)
  const [showSalary, setShowSalary] = useState<boolean>(DEFAULT_TIMESHEET_SETTINGS.showSalary)

  const toggleProjectList = () => {
    setShowProjectList((prev) => !prev)
  }

  const toggleSalary = () => {
    setShowSalary((prev) => !prev)
  }

  const selectedProjectList: IOption[] = watch('projects')
  const calendarRef: MutableRefObject<FullCalendar> = useRef<FullCalendar>(null)

  function handleOpenModal(): void {
    setIsOpenModal(true)
  }

  function handleOpenSmartTimesheetModal(): void {
    setIsOpenSmartTimesheetModal(true)
  }

  async function fetchData(): Promise<void> {
    await userProjectStore.getAllNameProjectList(EConditionGetList.ALL)
    await adminTaskTypeStore.getTaskTypeList()
    await fetchTimesheets()
  }

  async function fetchTimesheets(): Promise<void> {
    const projectIds: string[] = getValidArray(selectedProjectList)
      .filter((project: IOption) => project.value !== 'all')
      .map((project: IOption) => project.value)
    if (projectIds?.length !== 0) {
      let period: string
      switch (viewMode) {
        case ECalendarViewType.DAY_GRID_MONTH:
        case ECalendarViewType.TIME_GRID_DAY:
          period = EWorkingHourPeriods.MONTH
          break
        case ECalendarViewType.TIME_GRID_WEEK:
          period = EWorkingHourPeriods.WEEK
          break
        default:
          break // Optionally handle the default case or do nothing
      }
      await userTimesheetStore.fetchTimesheets(projectIds, selectedDateFromCalendar, period)
    } else {
      userTimesheetStore.resetTimesheetList()
    }
  }

  const projectsOption: IOption[] = [...getActiveProjectOptions(projectNameList)]
  const activeProjectIds: string[] = projectsOption.map((project: IOption) => project.value)

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    userProfileStore.getProfileDetail()
    const userId: string = localStorage.getItem('userId')
    userProfileStore.fetchDashboardUser(
      EWorkingHourPeriods.MONTH,
      dayjs(selectedDateFromCalendar).format('DD-MM-YYYY'),
      userId
    )
  }, [isLoadNewTimesheetList, selectedDateFromCalendar])

  useEffect(() => {
    if (isValidArray(projectsOption)) {
      const defaultOption: IOption[] = [...generateProjectOptionsWithStatus(projectNameList)]
      setValue('projects', defaultOption)
    }
  }, [projectNameList])

  useEffect(() => {
    fetchTimesheets()
  }, [selectedProjectList, isLoadNewTimesheetList, selectedDateFromCalendar, viewMode])

  useEffect(() => {
    if (location.search === '?modal=smart-timesheet') {
      handleOpenSmartTimesheetModal()
    }
  }, [location])

  useEffect(() => {
    const savedSettings = localStorage.getItem(TIMESHEET_SETTINGS_STORAGE_KEY)
    if (savedSettings) {
      try {
        const settings: TimesheetSettings = JSON.parse(savedSettings)
        setViewMode(settings.viewMode)
        calendarRef.current?.getApi().changeView(settings.viewMode)
        setShowProjectList(settings.showProjectList)
        setShowSalary(settings.showSalary)
      } catch (error) {
        console.error(Messages.failedToParseTimesheetSettings, error)
      }
    }
  }, [])

  useEffect(() => {
    const currentSettings: TimesheetSettings = {
      viewMode,
      showProjectList,
      showSalary,
    }
    localStorage.setItem(TIMESHEET_SETTINGS_STORAGE_KEY, JSON.stringify(currentSettings))
  }, [viewMode, showProjectList, showSalary])

  return (
    <VStack marginTop={6} spacing={6} width="full">
      <FormProvider {...methods}>
        <HeaderSection
          viewMode={viewMode}
          setViewMode={setViewMode}
          calendarRef={calendarRef}
          handleOpenModal={handleOpenModal}
          handleOpenSmartTimesheet={handleOpenSmartTimesheetModal}
          showProjectList={showProjectList}
          showSalary={showSalary}
          onToggleProjectList={toggleProjectList}
          onToggleSalary={toggleSalary}
        />
        {(showProjectList || showSalary) && (
          <HStack
            alignItems="flex-start"
            width="full"
            backgroundColor={backgroundGrey50}
            gap={4}
            border="1px"
            borderColor={borderGray200}
            borderRadius={2}>
            {showProjectList && (
              <VStack alignItems="flex-start" padding={4} maxWidth="60%">
                <Text p={0} m={0} color={textGrey500} fontWeight={500}>
                  Projects
                </Text>
                <GroupChips fieldName="projects" />
              </VStack>
            )}
            {showSalary && (
              <HStack>
                {isEmployee && (
                  <VStack alignItems="flex-start" padding={4}>
                    <Text p={0} m={0} color={textGrey500} fontWeight={500}>
                      Fulltime
                    </Text>
                    <HStack justify="space-between" mb={2}>
                      <Progress
                        w={'142px'}
                        value={getTimeLogProgressPercentage(totalWorkingTime)}
                        size={'sm'}
                        sx={{
                          '& > div': {
                            backgroundColor: getTimeLogProgressColor(totalWorkingTime),
                          },
                        }}
                        borderRadius="full"
                      />
                      <Text fontSize="20px" fontWeight={700} color={getTimeLogProgressColor(totalWorkingTime)}>
                        {getTimeLogProgressPercentage(totalWorkingTime)}%
                      </Text>
                    </HStack>
                  </VStack>
                )}
                <VStack alignItems="flex-start" padding={4}>
                  <Text p={0} m={0} color={textGrey500} fontWeight={500}>
                    {partnerCategory && partnerCategory === capitalize(EPartnerCategory.EMPLOYEE) ? 'Extra' : ''} Salary
                  </Text>
                  <Text fontSize="20px" fontWeight={700} color={textGrey700}>
                    {isEmployee ? totalExtraProjectSalary.toLocaleString() : totalSalary.toLocaleString()} đ
                  </Text>
                </VStack>
              </HStack>
            )}
          </HStack>
        )}
        <CalendarSection
          calendarRef={calendarRef}
          handleOpenModal={handleOpenModal}
          activeProjectIds={activeProjectIds}
        />
        <TimeSheetModal
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          filteredProjects={projectsOption}
          filteredProjectsForEdit={selectedProjectList}
        />
        <SmartTimesheetModal
          isOpenModal={isOpenSmartTimesheetModal}
          setIsOpenModal={setIsOpenSmartTimesheetModal}
          projectOptions={projectsOption}
        />
      </FormProvider>
    </VStack>
  )
}

export default observer(TimeSheetUser)
