import { useContext } from 'react'
import Button from 'components/Button'
import { TimesheetModalContext } from '../../context/timesheetModal.context'
interface IButtonOptionProps {
  isEditMode: boolean
  onClose: () => void
}

const ButtonOption = (props: IButtonOptionProps) => {
  const { isEditMode, onClose } = props
  const { isLoading } = useContext(TimesheetModalContext)

  return (
    <>
      <Button variant="outline" onClick={onClose} isLoading={isLoading} customStyles={{ marginRight: '12px' }}>
        Cancel
      </Button>

      <Button type="submit" isLoading={isLoading} disabled={isLoading}>
        {isEditMode ? 'Edit' : 'Add'} Timesheet
      </Button>
    </>
  )
}

export default ButtonOption
