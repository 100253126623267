import apiV2 from 'API/api'
import { APIRouters } from 'API/APIRouters'
import { ISuccessResponse } from 'API/interface'
import { AxiosResponse } from 'axios'
import { ILeaveTypeListResponse, ILeaveType } from 'types/leaveRequest'
import { ILeaveTypeDataFromCreateForm } from './constants'

export async function getLeaveTypeListAPI(): Promise<ISuccessResponse<ILeaveTypeListResponse>> {
  try {
    const response = await apiV2.get(APIRouters.leaveType.list.value)
    return response
  } catch (error) {
    throw error
  }
}

export async function createLeaveTypeAPI(leaveRequestTypes: ILeaveTypeDataFromCreateForm): Promise<string> {
  try {
    const url: string = APIRouters.leaveType.value
    const response: AxiosResponse = await apiV2.post(url, leaveRequestTypes)
    return response?.data?.message || ''
  } catch (error) {
    throw error
  }
}

export async function deleteLeaveTypeAPI(leaveTypeId: string): Promise<string> {
  try {
    const url: string = APIRouters.leaveType.leaveTypeId.value(leaveTypeId)
    const response: AxiosResponse = await apiV2.delete(url)
    return response?.data?.message || ''
  } catch (error) {
    throw error
  }
}

export async function updateLeaveTypeAPI(leaveType: ILeaveType): Promise<string> {
  try {
    const url: string = APIRouters.leaveType.leaveTypeId.value(leaveType.id)
    const response: AxiosResponse = await apiV2.patch(url, leaveType)
    return response?.data?.message || ''
  } catch (error) {
    throw error
  }
}
