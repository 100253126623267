import { ITimeSheetToFE } from 'API/timesheet/constants'
import dayjs from 'dayjs'
import { DEFAULT_PROJECT_COLOR } from 'constants/common'
import { isValidArray, getValidArray } from 'utils/commonUtils'
import { ICalendarEvent } from '../types'

export function generateEvents(timesheetList: ITimeSheetToFE[]): ICalendarEvent[] {
  if (!isValidArray(timesheetList)) {
    return []
  }
  const filteredTimesheetList = getValidArray(timesheetList).filter((item: ITimeSheetToFE) => {
    return Boolean(item?.timesheetProject)
  })

  return getValidArray(filteredTimesheetList).map((timesheet: ITimeSheetToFE) => {
    const currentProjectColor: string = timesheet?.timesheetProject?.color ?? DEFAULT_PROJECT_COLOR

    return {
      id: timesheet?.id,
      title: timesheet?.title,
      start: dayjs(timesheet?.start_time).toISOString(),
      end: dayjs(timesheet?.end_time).toISOString(),
      projectColor: currentProjectColor,
      extendedProps: {
        timesheetProject: {
          id: timesheet?.timesheetProject?._id,
        },
      },
    }
  })
}
