import { IProjectNameResponse } from 'API/project/constants'
import { makeColorBolder } from 'components/Calendar/utils'
import { toJS } from 'mobx'
import { textGreen500, textRed500, textYellow500 } from 'theme/globalStyles'
import { IProject } from 'types/project'
import { IOption } from 'constants/common'
import { DEFAULT_PROJECT_COLOR } from 'constants/common'
import { isValidArray, getValidArray } from 'utils/commonUtils'
import { IPayloadOfAutoSelectTaskType } from '../../constants'
import { IProjectItem } from '../components/Dropdown/DropdownButton'

export function selectTaskTypeFromTaskNameAndDescription(payload: IPayloadOfAutoSelectTaskType) {
  const { taskTypeList, taskName = '', description = '', currentTaskTypeList = [] } = payload
  const taskTypeListData = toJS(taskTypeList)
  const results = [...currentTaskTypeList]

  const lowerCaseTaskName = taskName.toLowerCase()
  const lowerCaseDescription = description.toLowerCase()

  taskTypeListData.forEach((item) => {
    const isMatch = getValidArray(item.keywords).some((keyword) => {
      const lowerCaseKeyword = keyword.toLowerCase()
      return lowerCaseTaskName.includes(lowerCaseKeyword) || lowerCaseDescription.includes(lowerCaseKeyword)
    })

    if (isMatch) {
      const newItem = { label: item.value, value: item.id }
      const isIncludedInCurrentTaskType = results.some((currentTaskType) => currentTaskType.value === item.id)

      if (!isIncludedInCurrentTaskType) {
        results.push(newItem)
      }
    }
  })

  return results
}

export function generateProjectItems(projects: IProjectNameResponse[], filteredProjects: IOption[]): IProjectItem[] {
  if (!isValidArray(projects)) {
    return []
  }
  let selectedProjects: IProjectNameResponse[] = projects
  if (filteredProjects && isValidArray(filteredProjects)) {
    selectedProjects = projects.filter((project: IProject) => {
      return filteredProjects.some((filteredProject: IOption) => {
        return filteredProject.value === project.id
      })
    })
  }
  return getValidArray(selectedProjects).map((project: IProject) => {
    return {
      title: project?.name,
      value: project?.id,
      borderColor: makeColorBolder(project?.color ?? DEFAULT_PROJECT_COLOR),
      color: project?.color ?? DEFAULT_PROJECT_COLOR,
      paymentCategory: project?.paymentCategory,
      budgetRemain: project?.budgetRemain,
      currencyName: project?.currencyName,
    }
  })
}

export function getTimeLogProgressColor(totalWorkingTime: number): string {
  const now = new Date()
  const currentDay = now.getDate()
  const requiredHours = (160 / 20) * currentDay
  const ratio = totalWorkingTime / requiredHours

  if (ratio >= 0.8) return textGreen500
  if (ratio > 0.5) return textYellow500
  return textRed500
}

export function getTimeLogProgressPercentage(totalWorkingTime: number): number {
  if (totalWorkingTime < 0) return 0
  const now = new Date()
  const currentDay = now.getDate()
  const requiredHours = (160 / 20) * currentDay
  return Math.round((totalWorkingTime / requiredHours) * 100)
}
