import { useEffect, useState, useRef } from 'react'
import { ChevronLeftIcon, ChevronRightIcon, ChevronDownIcon } from '@chakra-ui/icons'
import { Box, HStack, Text, Tooltip, Menu, MenuList, MenuItem, ButtonGroup, MenuButton } from '@chakra-ui/react'
import { Calendar } from '@fullcalendar/core'
import Button from 'components/Button'
import { ECalendarViewType } from 'components/Calendar/constants'
import MultipleSelect from 'components/MultipleSelect'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import DatePicker from 'react-datepicker'
import {
  backgroundBlue600,
  backgroundBlue800,
  backgroundBlueSecondary,
  borderGray200,
  textWhite,
} from 'theme/globalStyles'
import { IOption } from 'constants/common'
import { getFormattedDateWith_MMMDDYYYY } from 'utils/dateFormatUtils'
import { generateProjectOptionsWithStatus } from 'utils/dropdownOptionUtils'
import { useStores } from 'utils/hooks/useStores'
import CustomizeDropdown from '../CustomizeDropdown/CustomizeDropdown'
import { BoxWrapper } from './headerSection.styles'
import { IHeaderSectionProps } from './interface'

const HeaderSection = (props: IHeaderSectionProps) => {
  const { userProjectStore, userTimesheetStore } = useStores()
  const { projectNameList } = userProjectStore
  const { groups } = userTimesheetStore

  const {
    viewMode,
    setViewMode,
    calendarRef,
    handleOpenModal,
    handleOpenSmartTimesheet,
    showProjectList,
    showSalary,
    onToggleProjectList,
    onToggleSalary,
  } = props
  const [dateTitle, setDateTitle] = useState<string>(dayjs().format('MMMM, YYYY'))
  const [datePickerVisible, setDatePickerVisible] = useState(false)
  const [startDate, setStartDate] = useState(new Date())

  const projectsOption: IOption[] = [...generateProjectOptionsWithStatus(projectNameList)]

  const buttonGroupRef = useRef(null)
  const [buttonGroupWidth, setButtonGroupWidth] = useState(0)

  useEffect(
    function updateButtonGroupWidth() {
      if (buttonGroupRef.current) {
        setButtonGroupWidth(buttonGroupRef.current.offsetWidth + 2)
      }
    },
    [buttonGroupRef]
  )

  function toggleDatePicker(): void {
    setDatePickerVisible(!datePickerVisible)
  }

  function navigateToSelectedDate(date: Date): void {
    const calendarApi: Calendar = calendarRef.current?.getApi()
    calendarApi.gotoDate(date)
    handleDateTitle()
  }

  function onClickViewMode(currentView: ECalendarViewType): void {
    const calendarApi: Calendar = calendarRef.current?.getApi()

    if (calendarApi) {
      calendarApi.changeView(currentView)
    }
    setViewMode(currentView)
  }

  function onClickTodayNavigation(): void {
    const calendarApi: Calendar = calendarRef.current?.getApi()
    calendarApi.today()
    handleDateTitle()
  }

  function onClickPreviousNavigation(): void {
    const calendarApi: Calendar = calendarRef.current?.getApi()
    calendarApi.prev()
    handleDateTitle()
  }

  function onClickNextNavigation(): void {
    const calendarApi: Calendar = calendarRef.current?.getApi()
    calendarApi.next()
    handleDateTitle()
  }

  function handleDateTitle(): void {
    const calendarApi: Calendar = calendarRef.current?.getApi()
    const currentDate: Date = calendarApi.getDate()
    const formattedDate: string = dayjs(currentDate).format('YYYY-MM-DD')
    userTimesheetStore.setSelectedDateFromCalendar(formattedDate)
    if (viewMode === ECalendarViewType.DAY_GRID_MONTH || viewMode === ECalendarViewType.TIME_GRID_WEEK) {
      setDateTitle(getFormattedDateWith_MMMDDYYYY(currentDate))
    } else {
      setDateTitle(getFormattedDateWith_MMMDDYYYY(currentDate))
    }
  }

  useEffect(() => {
    handleDateTitle()
  }, [viewMode])

  return (
    <HStack justifyContent="space-between" width="full" height={5} alignItems="center">
      <HStack>
        <ChevronLeftIcon cursor="pointer" boxSize={6} onClick={onClickPreviousNavigation} />
        <Box style={{ position: 'relative' }}>
          <Text
            fontWeight="bold"
            cursor="pointer"
            color="gray.800"
            fontSize="md"
            onClick={toggleDatePicker}
            _hover={{
              backgroundColor: '#e2e8f0',
            }}>
            {dateTitle}
          </Text>
          {datePickerVisible && (
            <Box style={{ position: 'absolute', zIndex: 9999 }}>
              <DatePicker
                selected={startDate}
                onChange={(date) => {
                  setDatePickerVisible(false)
                  setStartDate(date)
                  navigateToSelectedDate(date)
                }}
                inline
              />
            </Box>
          )}
        </Box>
        <Button variant="outline" onClick={onClickTodayNavigation}>
          Today
        </Button>
        <ChevronRightIcon cursor="pointer" boxSize={6} onClick={onClickNextNavigation} />
      </HStack>
      <HStack alignItems="center" spacing={4}>
        <BoxWrapper minWidth="190px">
          <MultipleSelect
            name="projects"
            placeholder="Select Projects"
            options={projectsOption}
            selectedEntityName="Projects"
          />
        </BoxWrapper>
        <CustomizeDropdown
          viewMode={viewMode}
          onViewModeChange={onClickViewMode}
          showProjectList={showProjectList}
          showSalary={showSalary}
          onToggleProjectList={onToggleProjectList}
          onToggleSalary={onToggleSalary}
        />
        <Tooltip label={groups && !groups[0]?.isActive ? 'User is not active to log work' : ''}>
          <ButtonGroup isAttached variant="solid" borderRadius="6px" ref={buttonGroupRef}>
            <Button disabled={groups && !groups[0]?.isActive} onClick={handleOpenModal}>
              Add Timesheet
            </Button>
            <Menu>
              <MenuButton
                cursor="pointer"
                borderLeft={`1px solid ${borderGray200}`}
                borderRadius="6px"
                backgroundColor={backgroundBlueSecondary}
                color={textWhite}
                _hover={{ backgroundColor: backgroundBlue600 }}
                _active={{ backgroundColor: backgroundBlue800 }}>
                <ChevronDownIcon />
              </MenuButton>
              <MenuList minW={`${buttonGroupWidth}px`} width={`${buttonGroupWidth}px`} zIndex={10}>
                <MenuItem cursor="pointer" onClick={handleOpenSmartTimesheet}>
                  Smart timesheet
                </MenuItem>
              </MenuList>
            </Menu>
          </ButtonGroup>
        </Tooltip>
      </HStack>
    </HStack>
  )
}

export default observer(HeaderSection)
