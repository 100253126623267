import { ITaskType } from 'API/taskType/constants'
import { ECalendarViewType } from 'components/Calendar/constants'
import { IOption } from 'components/Dropdown/types'

export enum EProjectStage {
  DELETED = 'Deleted',
  REMOVED = 'Removed',
  ARCHIVED = 'Archived',
  ACTIVE = 'Active',
  DISABLED = 'Disabled',
}

export const STATUS_COLORS = {
  [EProjectStage.ACTIVE]: '#4fad33',
  [EProjectStage.DELETED]: '#777777',
  [EProjectStage.REMOVED]: '#777777',
  [EProjectStage.ARCHIVED]: '#777777',
  [EProjectStage.DISABLED]: '#777777',
}

export interface IPayloadOfAutoSelectTaskType {
  taskName?: string
  description?: string
  taskTypeList: ITaskType[]
  currentTaskTypeList?: IOption[]
}

export interface TimesheetSettings {
  viewMode: ECalendarViewType
  showProjectList: boolean
  showSalary: boolean
}

export const DEFAULT_TIMESHEET_SETTINGS: TimesheetSettings = {
  viewMode: ECalendarViewType.TIME_GRID_WEEK,
  showProjectList: false,
  showSalary: false,
}

export const TIMESHEET_SETTINGS_STORAGE_KEY = 'timesheet_settings'

export interface ICustomizeDropdownProps {
  viewMode: ECalendarViewType
  onViewModeChange: (view: ECalendarViewType) => void
  showProjectList: boolean
  showSalary: boolean
  onToggleProjectList: () => void
  onToggleSalary: () => void
}