import { Dispatch, MutableRefObject, SetStateAction } from 'react'
import { StringMap } from 'quill'
import { IOption } from 'constants/common'
import { IProjectItem } from '../Dropdown/DropdownButton'

export interface ITimeSheetForm {
  timeTotal: IOption
  startDate: Date
  endDate: Date
  startTime: string
  tag: IOption
  description: string
  project: IProjectItem
  taskLink: string
  taskName: string
  remuneration?: number
  isFixedPriceProject: boolean
  taskTypes?: IOption[]
}

export const EDITOR_MODULES: StringMap = {
  toolbar: [[{ list: 'bullet' }], ['bold', 'italic', 'underline'], [{ list: 'ordered' }], ['link']],
}

export enum ETimeSheetMode {
  MANUAL = 'manual',
  SHIFT = 'shift',
}

export interface ITimeSheetModalProps {
  isOpenModal: boolean
  setIsOpenModal: Dispatch<SetStateAction<boolean>>
  filteredProjects?: IOption[]
  filteredProjectsForEdit?: IOption[]
}

export interface ITimesheetModalContext {
  isLoading: boolean
  startDate: Date
  setStartDate: Dispatch<SetStateAction<Date>>
  endDate: Date
  setEndDate: Dispatch<SetStateAction<Date>>
  timesheetMode: string
  setTimesheetMode: Dispatch<SetStateAction<string>>
  startTime: string
  setStartTime: Dispatch<SetStateAction<string>>
  totalTimeOptions: IOption[]
  setTotalTimeOptions: Dispatch<SetStateAction<IOption[]>>
  taskDescription: string
  setTaskDescription: Dispatch<SetStateAction<string>>
  onBlurDescriptionEditor: () => void
  quillRef: MutableRefObject<undefined>
}
