import { handleError } from 'API/error'
import { createLeaveTypeAPI, getLeaveTypeListAPI, deleteLeaveTypeAPI, updateLeaveTypeAPI } from 'API/leaveType'
import { ILeaveTypeDataFromCreateForm } from 'API/leaveType/constants'
import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import { ILeaveType } from 'types/leaveRequest'
import { Messages } from 'constants/index'
import RootStore from '../rootStore'

class AdminLeaveTypeStore {
  rootStore: RootStore
  leaveTypeList: ILeaveType[] = []
  isLoadNewLeaveTypeList: boolean = false
  isLoadingLeaveTypeList: boolean = false

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async getLeaveTypeList(): Promise<void> {
    try {
      this.isLoadingLeaveTypeList = true
      const { data } = await getLeaveTypeListAPI()
      this.leaveTypeList = data.leaveRequestTypes
    } catch (error) {
      handleError(error, 'src/store/admin/AdminLeaveTypeStore.ts', 'getLeaveTypeList', false, true)
    } finally {
      this.isLoadingLeaveTypeList = false
    }
  }

  public async createLeaveType(leaveRequestTypes: ILeaveTypeDataFromCreateForm): Promise<void> {
    try {
      this.isLoadNewLeaveTypeList = true
      await createLeaveTypeAPI(leaveRequestTypes)
      toast.success(Messages.createLeaveRequestTypeSuccess)
    } catch (error) {
      handleError(error, 'src/store/admin/AdminLeaveTypeStore.ts', 'createLeaveType', false, true)
    } finally {
      this.isLoadNewLeaveTypeList = false
    }
  }

  public async deleteLeaveType(leaveTypeId: string): Promise<void> {
    try {
      this.isLoadNewLeaveTypeList = true
      await deleteLeaveTypeAPI(leaveTypeId)
      toast.success(Messages.deleteLeaveTypeSuccess)
    } catch (error) {
      handleError(error, 'src/store/admin/AdminLeaveTypeStore.ts', 'deleteLeaveType', false, true)
    } finally {
      this.isLoadNewLeaveTypeList = false
    }
  }

  public async updateLeaveType(leaveType: ILeaveType): Promise<void> {
    try {
      this.isLoadNewLeaveTypeList = true
      await updateLeaveTypeAPI(leaveType)
      toast.success(Messages.updateLeaveTypeSuccess)
    } catch (error) {
      handleError(error, 'src/store/admin/AdminLeaveTypeStore.ts', 'updateLeaveType', false, true)
    } finally {
      this.isLoadNewLeaveTypeList = false
    }
  }
}
export default AdminLeaveTypeStore
